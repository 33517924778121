<template>
	<table class="bl-datatable" v-if="meta && (!hideEmpty || (!currentRequest && data.length))">
		<thead>
			<tr>
				<th v-for="(field, k) in meta" :key="k">{{ field.label }}</th>
				<th v-if="allowDelete" style="width: 1px;"></th>
			</tr>
		</thead>
		<tbody>
			<tr v-if="currentRequest">
				<td colspan="100" style="padding: 0;"><div class="bl-loader-line"></div></td>
			</tr>
			<tr v-for="item in data" :key="item.id">
				<td v-for="(field, k) in meta" :key="k" @click="clickLine($event, item)">
					<component :is="{template: '<span>' + item.formatted[k] + '</span>'}"></component>
				</td>
				<td v-if="allowDelete" style="padding: 0 7px;">
					<button type="button" @click="deleteLine(item)" class="bl-icon-button" style="padding: 0; font-size: 20px;">delete</button>
				</td>
			</tr>
			<tr v-if="!currentRequest && !data.length">
				<td colspan="100"><BlNoData /></td>
			</tr>
		</tbody>
	</table>
</template>

<script>
import { Api, ModelChangeEventHelpers } from 'ModelBundle'
import { Realtime } from 'InterfaceBundle'

export default {
	name: 'BlFormDeferredList',
	props: {
		form: {
			required: true,
			type: String
		},
		fields: {
			required: true,
			type: Array
		},
		hideEmpty: {
			required: false,
			default: true,
			type: Boolean
		},
		allowDelete: {
			required: false,
			default: true,
			type: Boolean
		}
	},
	emits: ['click'],
	data() {
		return {
			data: null,
			meta: null,
			currentRequest: false
		}
	},
	created() {
		this.load()
		this.rt = ModelChangeEventHelpers.listen('internals.formdeferred')
		this.rt.subscribe(() => this.load())
	},
	methods: {
		load() {
			this.currentRequest = true
			Api.post('form/deferred-list/' + this.form, {fields: this.fields}).then(resp => {
				this.data = resp.data
				this.meta = resp.metadata
				this.currentRequest = false
			})
		},
		clickLine(event, item) {
			event.preventDefault()
			event.stopPropagation()
			this.$emit('click', item.id)
			return false
		},
		deleteLine(item) {
			Realtime.emit('interface.button', {
				id:'archive',
				data: {
					model: 'internals.formdeferred',
					id: item.id
				}
			})
		}
	}
}
</script>

<style scoped>
	.bl-datatable tr {
		cursor: pointer;
	}
</style>