<template>
	<ul>
		<li v-for="item in node" :key="item.id">
			<div @click="item.disabled ? null : blFormFieldTreeValue(item.id)" :class="{selected: blFormFieldTreeIsSelected(item.id), disabled: item.disabled}" :style="{paddingLeft: ((depth * 30) + 10) + 'px', width: 'calc(100% - ' + ((depth * 30) + 10) + 'px)'}">
				<button type="button" class="material-icons" :class="{opened: item.opened}" @click="$event.stopPropagation(); item.opened = !item.opened" v-if="item.nodes">arrow_drop_down</button>
				<span>{{ item.name }}</span>
				<button tabindex="-1" v-if="newitem" type="button" class="material-icons newitem" @click="$event.stopPropagation(); blFormFieldTreeNewItem(item)">add</button>
			</div>
			<BlFormFieldTreeNode :node="item.nodes" v-if="item.nodes && item.opened" :depth="depth + 1" :newitem="newitem" />
		</li>
	</ul>
</template>

<script>
export default {
	name: 'BlFormFieldTreeNode',
	props: {
		node: Array,
		newitem: Boolean,
		depth: {
			default: 0
		}
	},
	inject: ['blFormFieldTreeValue', 'blFormFieldTreeIsSelected', 'blFormFieldTreeNewItem']
}
</script>

<style scoped>

	ul {
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			> div {
				display: flex;
				align-items: center;
			}

			> div > button {
				border: 0;
				background: transparent;
				cursor: pointer;
				margin: -1px -5px 0 0;
				padding: 0;
				transition: transform .2s;
				color: var(--bl-legend);
				transform: rotate(-90deg);
				user-select: none;
			}

			> div > button.opened {
				transform: rotate(0);
			}

			> div > button.newitem {
				display: none;
			}

			> div:hover > button.newitem {
				display: block;
				margin-left: -120px;
				margin-right: 0px;
			}

			> div > span {
				transition: background-color .2s, color .2s;
				padding: 10px;
				user-select: none;
				flex: 1;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			> div.selected > span {
				color: var(--bl-primary);
			}

			> div:not(.disabled) {
				cursor: pointer;
			}

			> div.disabled > span {
				color: var(--bl-legend);
			}

			> div:not(.disabled):hover {
				background: var(--bl-background);
			}
		}
	}
</style>
