<template>
	<div class="formTreeContainer" v-bl-dropdown="{input: 'input', list: '.treeContainer', value: dropdownOptions}">
		<div class="bl-input __suffix" :class="{'bl-input__value': hasValue(), dropdownOpened: dropdownOptions.opened, errored: field.isErrored() && field.getTouched()}">
			<label @click="$refs.field.focus()">{{ field.label }}</label>
			<input ref="field" type="text" v-model="label" readonly @focus="field.setTouched()" />
			<span class="suffix material-icons" @click="$refs.field.focus()" style="visibility: visible; cursor: pointer;">arrow_drop_down</span>
		</div>
		<div class="treeContainer bl-light-scroll">
			<BlFormFieldTreeNode :node="field.options.tree" :newitem="field.options.newitem ? true : false" />
		</div>
	</div>
</template>

<script>
import BlFormFieldTreeNode from './BlFormFieldTreeNode'
import { Dialog, Router } from 'InterfaceBundle'
import { FormEvents } from 'FormBundle'

export default {
	name: 'BlFormFieldTree',
	props: ['field', 'tabindex'],
	inject: ['blFormLiveUpdateFields'],
	components: {
		BlFormFieldTreeNode
	},
	data() {
		return {
			dropdownOptions: {},
			model: this.field.value,
			label: null
		}
	},
	methods: {
		select(value) {
			if(this.field.options.multiple) {
				if(this.model.includes(value)) this.model.splice(this.model.indexOf(value), 1)
				else this.model.push(value)
			}
			else {
				this.model = value == this.model ? null : value
				this.dropdownOptions.close()
			}
			this.field.setValue(this.model)
			this.setLabel()
		},
		setLabel() {
			if(this.model) {
				const flatTreeRec = (tree, ret) => {
					for(let item of tree) {
						ret[item.id] = item.name
						if(item.nodes) flatTreeRec(item.nodes, ret)
					}
				}
				const treeFlat = {}
				flatTreeRec(this.field.options.tree, treeFlat)
				if(this.field.options.multiple) this.label = this.model.map(m => treeFlat[m]).join(', ')
				else this.label = treeFlat[this.model]
			}
			else this.label = ''
		},
		hasValue() {
			return this.field.options.multiple ? this.model.length : this.model
		},
		reload() {
			let req = {}
			req[this.field.getQualifiedName()] = {}
			return this.blFormLiveUpdateFields(req)
		},
		newItem(at) {
			let component = this.field.options.newitem.split(':')
			let field = null
			let propName = null
			if(component.length >= 2) field = component[1]
			if(component.length >= 3) propName = component[2]
			component = component[0]

			//Initialize value if required
			let readySub = field ? FormEvents.ready.once(form => form.form.getChild(field).setValue(at.id)) : null

			//Handle form submission
			const submitSub = FormEvents.submitted.once(response => {
				this.$nextTick(() => {
					this.reload().then(() => this.select(response.__id))
				})
				Dialog.close(false)
			})

			//Create dialog
			const matchingRoutes = Router.routes.filter(r => r.component == component)
			FormEvents.disableRouting = true
			let childProps = {}
			if(propName) childProps[propName] = this.field
			Dialog.custom({
				component: component,
				componentProps: childProps,
				title: matchingRoutes.length ? matchingRoutes[0].title : '',
				required: true,
				closeButton: true
			}).catch(() => {
				FormEvents.disableRouting = false
				if(readySub) readySub.unsubscribe()
				submitSub.unsubscribe()
			})
		}
	},
	created() {
		this.field.emitter.focus.subscribe(() => this.$refs.field.focus())
		this.field.emitter.change.subscribe(() => {
			this.model = this.field.value
			this.setLabel()
		})
		if(!this.model && this.field.options.multiple) this.model = []
		this.setLabel()
	},
	provide() {
		return {
			blFormFieldTreeValue: value => this.select(value),
			blFormFieldTreeNewItem: at => this.newItem(at),
			blFormFieldTreeIsSelected: value => this.field.options.multiple ? this.model.includes(value) : this.model == value
		}
	}
}
</script>

<style scoped>
	.formTreeContainer {
		position: relative;

		.bl-input > input {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			padding-right: 18px;
			width: calc(100% - 10px);
		}

		.bl-input.dropdownOpened {
			> input {
				outline: 2px solid var(--bl-primary);
				border-bottom-right-radius: 0;
				border-bottom-left-radius: 0;
			}

			> label {
				color: var(--bl-primary);
			}
		}
	}

	.treeContainer {
		position: absolute;
		background: var(--bl-surface);
		border: 2px solid var(--bl-primary);
		border-top: 1px solid var(--bl-border);
		border-bottom-left-radius: var(--bl-border-radius);
		border-bottom-right-radius: var(--bl-border-radius);
		overflow-y: auto;
		overflow-x: hidden;
		z-index: 10;
		width: 100%;
		margin-left: -2px;
		max-height: 350px;
	}
</style>
