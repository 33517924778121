<template>
	<BlButton :label="label" type="button" :disabled="currentRequest" ref="submit" tabindex="-1" class="outlined" icon="pending" @click="blFormDefer()" />
</template>

<script>
export default {
	name: 'BlFormDefer',
	inject: ['blFormSubmitChangeDetector', 'blFormDefer'],
	data() {
		return {
			label: null,
			currentRequest: null
		}
	},
	mounted() {
		if(!this.label) this.label = this.$t('form.defer')
		this.blFormSubmitChangeDetector.subscribe(this.changeFn)
	},
	methods: {
		changeFn(datum) {
			this.label = datum.saveButtonLabel ? datum.saveButtonLabel : this.$t('form.defer')
			this.currentRequest = datum.currentRequest
		}
	},
	unmounted() {
		this.blFormSubmitChangeDetector.unsubscribe(this.changeFn)
	}
}
</script>

<style scoped lang="scss">
</style>