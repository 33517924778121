<template>
	<div style="position: relative;">
		<div v-if="mobile" class="mobileVirtualScroller" :style="{minHeight: (mobileLineHeight * count.total) + 'px', backgroundImage: virtualBackgroundSvg}"></div>
		<table class="bl-datatable" :class="{selectMode: (selectable || dropdownMode) && data.length, dropdownMode: data.length && dropdownMode, mobile: mobile, inline: inlineTable, selectable: selectable && data.length}" :style="mobile ? {minHeight: ((mobileLineHeight * count.total) + 49) + 'px'} : {}">
			<slot></slot>
			<thead v-if="metadata" ref="tableHead">
				<tr class="labelHeader">
					<th v-if="selectable && data.length" :rowspan="mobile ? 1 : 2" style="width: 25px; vertical-align: top;" :style="{padding: hasFilters ? '35px 7px 0 7px' : '13px 7px 0 7px'}">
						<input class="bl-checkbox" type="checkbox" ref="globalCheckbox" @change="globalSelectionChange()" />
					</th>
					<th v-if="dropdownMode && data.length" rowspan="2" style="width: 25px;"></th>
					<th v-for="col in fieldNames" :key="col" :class="getClassList(col)" @click="setSort(col)" style="border-bottom: 0;">
						<div v-if="filters[col]" class="filterBackdrop"></div>
						<div style="display: flex; position: relative;">
							<icon v-if="mobile && filters[col]" class="mobileFilterIcon">filter_alt</icon>
							<span>{{ getLabel(col) }}</span>
							<icon v-if="sort == col || sort == '-' + col" :class="{reversed: sort == '-' + col, sort: true}">arrow_upward</icon>
						</div>
					</th>
				</tr>
				<tr v-if="hasFilters && !mobile">
					<th v-for="col in fieldNames" :key="col" :class="getClassList(col)" style="border-bottom: 0; border-top: 0; padding-top: 0; padding-bottom: 7px;">
						<div v-if="filters[col]" class="filterBackdrop"></div>
						<template v-if="fieldOptions[col].filter">
							<component :is="getFilterComponentName(col)" :initialValue="filtersState[col]" :metadata="{field: metadata[col], model: modelMeta}" :options="getFilterOptions(col)" @blListFilterChange="updateFilter(col, $event)" />
						</template>
					</th>
				</tr>
				<tr v-if="hasFilters && mobile">
					<td :colspan="getColspan()" style="padding: 0; border-top: 1px solid var(--bl-border); background: var(--bl-background); border-radius: 0; overflow: hidden;">
						<div class="mobileSearchWithTextContainer" v-if="mobileFilterSearch.enabled">
							<icon>search</icon>
							<input type="text" :placeholder="$t('model.list.search')" v-model="mobileFilterSearch.search" @keyup="loadData()" />
							<button class="bl-icon-button" v-if="mobileFilterSearch.search" @click="mobileFilterSearch.search = ''; loadData();">clear</button>
							<BlButton icon="filter_alt" class="outlined" :class="{legend: !hasActiveFilters()}" style="height: 49px;" @click="openMobileFilters()" />
						</div>
						<BlButton icon="filter_alt" :label="countActiveFilters() ? $t('model.list.filters{count}', {count: countActiveFilters()}) : $t('model.list.filters')" class="outlined" :class="{legend: !hasActiveFilters()}" style="width: 100%; height: 49px;" @click="openMobileFilters()" v-else />
					</td>
				</tr>
				<tr v-if="currentRequest && !mobile">
					<td :colspan="getColspan()" style="padding: 0; border: 0;">
						<div class="bl-loader-line" v-if="currentRequest"></div>
					</td>
				</tr>
				<tr v-if="erroredLoad && !currentRequest">
					<th :colspan="getColspan()">
						<div class="erroredLoad bl-card" style="width: calc(100% - 20px); border: 0;">
							<h1 style="color: var(--bl-error)"><icon>sync_problem</icon>{{ $t('model.dataLoadError') }}</h1>
						</div>
					</th>
				</tr>
			</thead>
			<tbody v-if="!hideBody">
				<tr v-if="allowAllSelection" style="min-height: 45px; height: 45px;">
					<td :colspan="getColspan()" style="text-align: center; padding: 7px;">
						<span v-if="allSelected" style="color: var(--bl-legend)">{{ $t('model.list.allSelected{total}{label}', {total: count.total, label: modelMeta.label}) }}</span>
						<div v-else>
							<span style="color: var(--bl-legend)">{{ $t('model.list.pageSelected{total}{label}', {total: data.length, label: modelMeta.label}) }}</span>
							<BlButton :label="$t('model.list.selectAll{total}{label}', {total: count.total, label: modelMeta.label})" class="dense outlined" :loading="allSelectionLoading" @click="selectAll()" />
						</div>
					</td>
				</tr>
				<tr v-if="metadata && mobile && count.from > 1" class="mobileOffsetCell">
					<td v-if="selectable" :style="{height: ((count.from - 0) * mobileLineHeight) + 'px'}"></td>
					<td v-for="col in fieldNames" :key="col" :style="{height: ((count.from - 0) * mobileLineHeight) + 'px'}" :class="getClassList(col)"></td>
				</tr>
				<template v-for="item in data" :key="item.id">
					<tr :class="getLineClassList(item)">
						<td v-if="dropdownMode" style="padding: 0;" @click="clickLine(item)">
							<button class="bl-icon-button dropdownButton" :class="{opened: dropdownLines[item.id]}">navigate_next</button>
						</td>
						<td v-if="selectable" class="selectableContainer">
							<input class="bl-checkbox" type="checkbox" v-model="selectedState[item.id]" @change="handleSelectionState()" />
						</td>
						<td v-for="col in fieldNames" :key="col" @click="clickLine(item, col)" v-link="getLink(item, col)" :class="getClassList(col)">
							<slot v-if="fieldOptions[col].slot" :name="col.replaceAll('.', '_')" v-bind="item"></slot>
							<span v-if="!fieldOptions[col].slot && item[col] && !item[col]._cn" v-html="item[col]"></span>
							<component v-if="!fieldOptions[col].slot && item[col] && item[col]._cn" :is="item[col]._cn" v-bind="item[col]._cp" />
						</td>
					</tr>
					<tr v-if="dropdownLines[item.id]" class="droppedDownRow">
						<td></td>
						<td :colspan="getColspan() - 1"><slot name="dropdown" v-bind="item"></slot></td>
					</tr>
				</template>
				<tr v-if="!data.length && modelMeta && !currentRequest" class="noDataCell">
					<td :colspan="getColspan(false)">
						<BlNoData />
					</td>
				</tr>
				<tr v-if="metadata && mobile && !isAtBottom" class="mobileOffsetCell" style="height: 0; max-height: none;">
					<td v-if="selectable" style="height: 100%; min-height: 0;"></td>
					<td v-for="col in fieldNames" :key="col" style="height: 100%; min-height: 0;" :class="getClassList(col)"></td>
				</tr>
				<tr v-if="hasAggregation && data.length" class="aggregations">
					<td v-if="selectable && data.length"></td>
					<td v-if="dropdownMode && data.length"></td>
					<td v-for="col in fieldNames" :key="col" :class="getClassList(col)">
						<div v-if="fieldOptions[col].aggregation" v-bl-tooltip="$t(aggregationMeta[col].name)">{{ aggregationData[col] ? aggregationData[col][0] : '' }}</div>
					</td>
				</tr>
			</tbody>
			<tfoot v-if="!hidePagination && !mobile">
				<tr>
					<td :colspan="getColspan()" style="padding: 0;">
						<div class="tableFooter">
							<div class="linesPerPage">
								<span>{{ $t('model.list.linesPerPage') }}</span>
								<div style="width: 72px !important; padding-left: 5px;">
									<BlSelect v-if="paginationOptions" :data="paginationOptions" v-model="options.limit" @change="loadData()" />
								</div>
								<button class="bl-icon-button linesPerPageSettings" v-bl-tooltip="$t('model.list.pagination.title')" @click="openPaginationSettings()">settings</button>
							</div>
							<div style="width: 100%;"></div>
							<div>
								{{ $t('model.list.page') }}
								<div style="width: 70px !important; padding-left: 5px;">
									<BlSelect :data="pagesSelect" v-model="options.page" @change="loadData()" />
								</div>
							</div>
							<div style="margin: 0 10px;">
								{{ $t('model.list.{from}{to}of{total}', {from: count.from, to: count.to, total: count.total}) }}
							</div>
							<button class="bl-icon-button" :disabled="options.page == 0" @click="pagination(-1)">keyboard_arrow_left</button>
							<button class="bl-icon-button" @click="pagination(1)" :disabled="options.page == pages[pages.length - 1]">keyboard_arrow_right</button>
						</div>
					</td>
				</tr>
			</tfoot>
		</table>
	</div>
</template>

<script>
import { Api, ModelChangeEventHelpers, BlListRegistry } from 'ModelBundle'
import { Router, EventEmitter, Variables, Dialog, Grid, ViewLoader, Realtime } from 'InterfaceBundle'

export default {
	name: 'BlList',
	props: ['fields', 'model', 'url', 'defaultSort', 'rawSort', 'selectable', 'defaultFilters', 'hidePagination', 'additionalFields', 'inlineTable', 'persistState', 'autoLoad', 'selectionTitle', 'routerSelectionState', 'hideBody', 'lineClass', 'disableMobileSearch', 'signal'],
	data() {
		return {
			data: [],
			modelMeta: null,
			options: {
				limit: 20,
				page: 0
			},
			count: {
				total: null,
				from: null,
				to: null
			},
			pages: [],
			pagesSelect: {},
			slotFields: [],
			filters: {},
			filtersState: {},
			metadata: null,
			sort: null,
			currentRequest: false,
			hasFilters: false,
			hasAggregation: false,
			aggregationData: {},
			aggregationMeta: {},
			fieldOptions: {},
			fieldNames: [],
			selectedState: {},
			allowAllSelection: false,
			allSelectionLoading: false,
			allSelected: null,
			dropdownMode: false,
			mobile: Variables.mobile && !this.dropdownMode && !this.inlineTable,
			dropdownLines: {},
			virtualBackgroundSvg: '',
			mobileLineHeight: 55,
			isAtBottom: false,
			mobileFilters: false,
			paginationOptions: null,
			mobileFilterSearch: {
				fields: [],
				search: '',
				enabled: false
			}
		}
	},
	watch: {
		defaultFilters: function() {
			if(this.lastDefaultFilters != JSON.stringify(this.defaultFilters)) {
				this.loadData()
				this.defaultFiltersChange.emit()
			}
		}
	},
	provide() {
		return {
			blList: this,
			blListAddField: field => this.slotFields.push(field),
			blListRemoveField: field => this.slotFields = this.slotFields.filter(f => f != field),
			blListUpdateField: () => this.handleFieldUpdate()
		}
	},
	created() {
		this.defaultFiltersChange = new EventEmitter()
		this.uid = BlListRegistry.register(this.model)
	},
	mounted() {
		if(this._fieldUploadDebouncer) clearTimeout(this._fieldUploadDebouncer)
		this.dataChange = new EventEmitter()
		setTimeout(() => {//For auto pagination, required to make sure element boundingBox is correct
			let autoAvailable = false
			let paginationSettings = ViewLoader.data.userPreferences.customSettings.blListPagination
			if(!paginationSettings) paginationSettings = ['a', 20, 50, 100]
			this.paginationOptions = {}
			for(let item of paginationSettings) {
				if(item == 'a' && this.$el.parentElement?.classList.contains('currentRouteContainer') && !this.mobile) {
					this.paginationOptions[this.$t('model.list.autopagination')] = 'auto'
					autoAvailable = true
				}
				else this.paginationOptions[item] = item
			}
			this.lastDefaultFilters = null
			if(this.defaultSort) this.sort = this.defaultSort
			this.restoreNavigation()
			if(this.mobile) this.options.limit = Math.ceil((window.innerHeight - 200) / this.mobileLineHeight) + 25
			else if(!Object.keys(this.paginationOptions).includes(this.options.limit)) {
				this.options.limit = paginationSettings[0]
				if(this.options.limit == 'a') this.options.limit = autoAvailable ? 'auto' : 20
			}

			if(this.autoLoad !== false) this.initialLoad()

			if(this.$slots.dropdown) this.dropdownMode = true

			if(this.mobile) this.virtualBackgroundSvg = `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='` + this.mobileLineHeight + `px'%3E%3Crect x='0%25' y='98%25' width='100%25' height='1%25' fill='` + Variables.colors[Variables.theme].border.replace('#', '%23') + `' /%3E%3C/svg%3E")`
		})
	},
	unmounted() {
		if(this.rts) this.rts.map(rt => ModelChangeEventHelpers.unsubscribe(rt))
		if(this.mobile) document.querySelector('.interfaceRouterContainer').removeEventListener('scroll', this.mobileLoader)
		if(this.intersectionObserver) this.$el.querySelectorAll('thead tr.labelHeader th').forEach(e => this.intersectionObserver.unobserve(e))
		if(this.realtimeSub) this.realtimeSub.unsubscribe()
		BlListRegistry.unregister(this.uid)
	},
	methods: {
		/**
		 * Initial data load
		 */
		initialLoad() {
			this._initialLoad = true
			this.realtimeSub = null
			this.loadData(true).once(valid => {
				if(valid) {
					const rtModels = this.modelMeta.children ? this.modelMeta.children : [this.modelMeta.name]
					this.rts = []
					for(let model of rtModels) {
						const rtListener = ModelChangeEventHelpers.listen(model)
						rtListener.subscribe(() => this.loadData())
						this.rts.push(rtListener)
					}

					this.$nextTick(() => {
						this.intersectionObserver = new IntersectionObserver( 
							(entries) => {
								for(let entry of entries) entry.target.classList.toggle('isSticky', entry.intersectionRatio < 1)
							},
							{threshold: [1]}
						)
						this.$el.querySelectorAll('thead tr.labelHeader th').forEach(e => this.intersectionObserver.observe(e))

						if(this.mobile) setTimeout(() => {
							const routerContainer = document.querySelector('.interfaceRouterContainer')
							if(this.restoreScroll) routerContainer.scrollTop = this.restoreScroll
							routerContainer.addEventListener('scroll', this.mobileLoader)
						}, 10)
					})
					this.$emit('ready')
				}
				else {
					//Try to reload initial when realtime connexion is back up
					this.realtimeSub = Realtime.status()
					this.realtimeSub.then(connected => {
						if(connected) {
							this.realtimeSub.unsubscribe()
							this.realtimeSub = null
							this.initialLoad()
						}
					})
				}
			})
		},
		/**
		 * Handle mobile loading from scroll event
		 */
		mobileLoader(event) {
			//Avoid first scroll event at 0
			if(!this.firstMobileLoader) {
				this.firstMobileLoader = true
				return
			}
			this.saveNavigation()
			const scrollPosition = event.target.scrollTop - (this.$el.offsetTop + this.$refs.tableHead.offsetHeight)
			const scrollFrom = Math.max(0, Math.ceil(scrollPosition / this.mobileLineHeight))
			const scrollTo = scrollFrom + this.getLimit() - 5
			const loadedFrom = this.options.page
			const loadedTo = this.options.page + this.getLimit() - 5

			let newPage = null
			if(scrollFrom < loadedFrom || scrollTo > loadedTo) newPage = scrollFrom - 10
			if(newPage < 0) newPage = 0
			else if(newPage > this.count.total) newPage = this.count.total

			if(newPage !== null && (Math.abs(this.options.page - newPage) > 10 || newPage === 0)) {
				this.options.page = newPage
				clearTimeout(this.mobileLoaderTimeout)
				this.mobileLoaderTimeout = setTimeout(() => this.loadData(), 100)
			}
		},
		/**
		 * Get colspan
		 * @param  {Boolean} withSelectable
		 * @return {Number}
		 */
		getColspan(withSelectable = true) {
			let fieldCount = 0
			for(let fieldName of this.fieldNames) {
				if(this.fieldOptions[fieldName]?.grid) fieldCount += Grid.matches(this.fieldOptions[fieldName].grid) ? 1 : 0
				else fieldCount++
			}
			return fieldCount + (withSelectable && this.selectable && this.data.length ? 1 : 0) + (withSelectable && this.dropdownMode ? 1 : 0)
		},
		/**
		 * Get fields
		 * @return {Array}
		 */
		getFields() {
			return this.fields ? this.fields : this.slotFields.filter(f => Object.values(f)[0].if)
		},
		/**
		 * Get class list for column
		 * @param  {String} col
		 * @return {Array}
		 */
		getClassList(col) {
			let ret = []
			if(!this.metadata[col]) return this._fieldsUpdateing ? [] : ['field-error']
			if(this.metadata[col].options.tableAlign == 'right') ret.push('numeric')
			if(this.fieldOptions[col].grid) ret.push('grid-' + this.fieldOptions[col].grid)
			return ret
		},
		/**
		 * Get class line for line
		 * @param  {object} item
		 * @return {Array}
		 */
		getLineClassList(item) {
			let ret = item.__classList ? item.__classList : []
			if(this.selectedState[item.id]) ret.push('selected')
			return ret
		},
		/**
		 * Set item classlist on load (from lineClass binding)
		 */
		setItemsClassList() {
			if(!this.lineClass) return
			const modelName = this.model.split('.').slice(-1)
			for(let key in this.lineClass) {
				let __applyClassList = () => false
				const code = '__applyClassList = (' + modelName + ') => { return ' + this.lineClass[key] + '}'
				eval(code)
				for(let item of this.data) {
					if(__applyClassList(item)) {
						if(!item.__classList) item.__classList = []
						item.__classList.push(key)
					}
				}
			}
		},
		/**
		 * Get filter component name
		 * @param  {String} column
		 * @return {String}
		 */
		getFilterComponentName(column) {
			if(!this.metadata[column]) return null
			let filterName = this.fieldOptions[column].filter
			if(typeof filterName == 'object') {
				if(filterName.component) return filterName.component
				filterName = filterName.type
			}
			filterName = filterName.charAt(0).toUpperCase() + filterName.slice(1)
			let typeName = this.metadata[column].filterType
			typeName = typeName.charAt(0).toUpperCase() + typeName.slice(1)
			return 'BlListFilter' + filterName + typeName
		},
		/**
		 * Get filter options
		 * @param  {string} column
		 * @return {object}
		 */
		getFilterOptions(column) {
			if(!this.metadata[column]) return null
			let filter = this.fieldOptions[column].filter
			if(typeof filter == 'object' && filter.options) return filter.options
			return {}
		},
		/**
		 * Set sort on column
		 * @param {String} column
		 */
		setSort(column) {
			if(this.sort == column) this.sort = '-' + column
			else this.sort = column
			this.loadData()
		},
		/**
		 * Get if table has active filers
		 * @return {Boolean}
		 */
		hasActiveFilters() {
			return this.countActiveFilters() > 0
		},
		/**
		 * Get number of active filters
		 * @return {Number}
		 */
		countActiveFilters() {
			return Object.values(this.filters).length
		},
		/**
		 * Get request params
		 * @return {Object}
		 */
		getRequestParams(reloadMetadata = false) {
			const listFilters = Object.values(this.filters).length ? ['&'].concat(Object.values(this.filters)) : null
			const defaultFilters = this.defaultFilters && this.defaultFilters.length ? ['&'].concat([this.defaultFilters]) : null
			const searchFilters = this.getMobileSearchFilters()
			let filters = null
			const filterCount = (listFilters ? 1 : 0) + (defaultFilters ? 1 : 0) + (searchFilters ? 1 : 0)
			if(filterCount == 1) filters = listFilters || defaultFilters || searchFilters
			else if(filterCount == 0) filters = []
			else {
				filters = ['&']
				if(listFilters) filters.push(listFilters)
				if(defaultFilters) filters.push(defaultFilters)
				if(searchFilters) filters.push(searchFilters)
			}

			let req = {
				data: {
					model: this.model,
					fields: this.fieldNames.map(name => {
						let ret = {name: name}
						if(this.fieldOptions[name] && this.fieldOptions[name].formatted === false) ret.formatted = false
						if(this.fieldOptions[name] && this.fieldOptions[name].disableLoad === true) ret.disableLoad = true
						return ret
					}),
					offset: this.hidePagination ? 0 : (this.options.page * (this.mobile ? 1 : this.getLimit())),
					limit: this.hidePagination ? -1 : this.getLimit(),
					filters: filters
				}
			}
			if(this.metadata && !reloadMetadata) req.data.metadata = false
			if(this.additionalFields) req.data.fields = req.data.fields.concat(this.additionalFields)
			if(!this.fieldNames.includes('id')) req.data.fields.push({name: 'id'})
			if(this.signal) req.data.signal = this.signal
			if(this.rawSort) req.data.sort = this.rawSort
			else if(this.sort) {
				let field = this.sort.substr(0, 1) == '-' ? this.sort.substr(1) : this.sort
				let order = this.sort.substr(0, 1) == '-' ? 'DESC' : 'ASC'
				req.data.sort = [{
					field: field,
					order: order
				}]
			}
			return req
		},
		getLimit() {
			if(!['auto', 'a'].includes(this.options.limit)) return this.options.limit
			const footerHeight = 55
			const headerHeight = this.hasFilters ? 100 : 55
			let height = window.innerHeight - this.$el.getBoundingClientRect().top - footerHeight - headerHeight
			if(localStorage.getItem('Interface.showDesignerToolbar')) height -= 35
			const columnHeight = 46
			return Math.floor(height / columnHeight)
		},
		getAggregationRequest() {
			let req = this.getRequestParams().data
			delete req.offset
			delete req.limit
			delete req.fields
			delete req.sort
			delete req.metadata
			req.aggregations = {
				values: [],
				group: []
			}

			for(let fieldName of this.fieldNames) {
				if(this.fieldOptions[fieldName].aggregation) req.aggregations.values.push({
					field: fieldName,
					operation: this.fieldOptions[fieldName].aggregation
				})
			}

			return req
		},
		/**
		 * Load data from API
		 */
		loadData(handleReject = false, reloadMetadata = false) {
			this.lastDefaultFilters = JSON.stringify(this.defaultFilters)
			let ret = new EventEmitter()
			this.resetSelection()
			if(this._firstDataLoadDone) this.saveNavigation()
			else this._firstDataLoadDone = true
			this.currentRequest = true

			this.fieldNames = []
			this.fieldOptions = {}
			for(let field of this.getFields()) {
				if(typeof field === 'string') {
					this.fieldNames.push(field)
					this.fieldOptions[field] = {}
					if(field != 'default' && this.$slots[field.replaceAll('.', '_')]) this.fieldOptions[field].slot = true
				}
				else {
					let fieldName = Object.keys(field)[0]
					this.fieldNames.push(fieldName)
					this.fieldOptions[fieldName] = field[fieldName]
					if(field[fieldName].filter) this.hasFilters = true
					if(field[fieldName].aggregation && !this.mobile) this.hasAggregation = true
					if(fieldName != 'default' && this.$slots[fieldName.replaceAll('.', '_')]) this.fieldOptions[fieldName].slot = true
				}
			}

			const req = this.getRequestParams(reloadMetadata)
			if(this.hasAggregation) req.aggregation = this.getAggregationRequest()
			Api.post('api/', req, {}, 'interface.datatable.' + this.uid).then(resp => {
				this.erroredLoad = false
				this.data = resp.data.data
				this.setItemsClassList()
				if(this.hasAggregation) {
					this.aggregationData = resp.aggregation.data
					this.aggregationMeta = resp.aggregation.metadata
				}
				if(resp.data.metadata) {
					this.metadata = resp.data.metadata
					this.modelMeta = resp.data.model
					if(this.mobile) this.initializeMobileSearch()
				}
				const limit = this.mobile ? 1 : this.getLimit()
				this.count = {
					total: resp.data.count,
					from: this.hidePagination ? 0 : (this.mobile ? req.data.offset : (this.options.page * limit + 1)),
					to: this.hidePagination ? -1 : ((this.options.page + 1) * limit)
				}
				this.isAtBottom = (req.data.offset + req.data.limit) >= resp.data.count
				if(this.count.to > this.count.total) this.count.to = this.count.total
				if(this.count.total == 0) this.count.from = 0
				this.currentRequest = false
				this.pages = Array.from(Array(Math.ceil(this.count.total / this.getLimit())).keys())
				this.pagesSelect = {}
				for(let page of this.pages) this.pagesSelect[page + 1] = page

				if(this.options.page > 0 && !this.pages.includes(this.options.page) && !this.mobile) {
					this.options.page = this.pages.length ? this.pages[this.pages.length - 1] : 0
					this.loadData()
				}
				if(handleReject) ret.emit(true)
				else ret.emit()
				this.$emit('dataChange', this.data)
				this.dataChange.emit()
			}, err => {
				if(err && err.name != 'AbortError') {
					this.currentRequest = false
					this.erroredLoad = true
				}
				if(handleReject) ret.emit(false)
			})
			return ret
		},
		/**
		 * Get link for item
		 * @param  {Object} item=
		 * @return {String}
		 */
		getLink(item, col) {
			if(this.$attrs.onClickLine || this.dropdownMode || this.fieldOptions[col].disableClick) return null
			if(this.url) {
				if(typeof this.url === 'string') return {url: [this.url, {id: item.id}], animate: 'forward'}
				else if(Array.isArray(this.url)) {
					let args = JSON.parse(JSON.stringify(this.url.length == 2 ? this.url[1] : {}))
					for(let k in args) {
						if(typeof args[k] === 'string' && args[k].includes('{{')) args[k] = args[k].replace(/{{ ?([a-zA-Z0-9.]+) ?}}/g, (match, group) => item[group])
					}
					return {url: [this.url[0], args], autoHide: false, animate: 'forward'}
				}
			}
			else if(item.__route) return {url: item.__route, autoHide: false, animate: 'forward'}
			else return null
		},
		/**
		 * Click on line
		 * @param  {object} item
		 */
		clickLine(item, col) {
			if(this.fieldOptions[col]?.disableClick) return
			if(this.dropdownMode) this.dropdownLines[item.id] = !this.dropdownLines[item.id]
			else this.$emit('clickLine', item)
		},
		/**
		 * Handle pagination
		 * @param  {Number} increment
		 */
		pagination(increment) {
			if(increment < 0 && this.options.page == 0) return
			if(increment > 0 && this.count.to == this.count.total) return
			this.options.page += increment
			this.loadData()
		},
		/**
		 * Update filter
		 * @param {String} col
		 * @param {Array}  event
		 */
		updateFilter(col, event, loadData = true) {
			if(event) {
				this.filters[col] = event[0]
				this.filtersState[col] = event[1]
			}
			else {
				delete this.filters[col]
				delete this.filtersState[col]
			}
			if(loadData) this.loadData()
		},
		/**
		 * Get label for column
		 * @param  {String} col
		 * @return {String}
		 */
		getLabel(field) {
			if(!this.metadata[field]) {
				return this._fieldsUpdateing ? '' : '[Field "' + field + '" does not exist]'
			}
			return this.fieldOptions[field].label || this.fieldOptions[field].label === '' ? this.fieldOptions[field].label : this.metadata[field].label
		},
		/**
		 * Save navigation in local storage
		 */
		saveNavigation() {
			if(this.persistState === false) return
			let value = {
				options: this.options,
				sort: this.sort,
				filters: this.filters,
				filtersState: this.filtersState
			}
			if(this.mobile) {
				value.mobileScroll = document.querySelector('.interfaceRouterContainer').scrollTop
				value.mobileSearch = this.mobileFilterSearch
			}
			localStorage.setItem('interface.datatable.' + this.uid, JSON.stringify(value))
		},
		/**
		 * Restore navigation
		 */
		restoreNavigation() {
			if(this.persistState === false) return
			let restore = localStorage.getItem('interface.datatable.' + this.uid)
			if(!restore) return
			restore = JSON.parse(restore)
			if(restore.options) this.options = restore.options
			if(restore.sort) this.sort = restore.sort
			if(restore.filters) this.filters = restore.filters
			if(restore.filtersState) this.filtersState = restore.filtersState
			if(restore.mobileScroll && this.mobile) this.restoreScroll = restore.mobileScroll
			if(restore.mobileSearch && this.mobile) this.mobileFilterSearch = restore.mobileSearch
		},
		/**
		 * Handle selection state change
		 */
		handleSelectionState() {
			this.allowAllSelection = false
			this.allSelected = null
			let dataCount = this.data.length
			let selectionCount = 0
			for(let id in this.selectedState) {
				if(this.selectedState[id]) selectionCount++
			}
			let indeterminate = false
			let selected = false
			if(dataCount == selectionCount) selected = true
			else if(selectionCount > 0) indeterminate = true
			if(this.$refs.globalCheckbox) {
				this.$refs.globalCheckbox.indeterminate = indeterminate
				this.$refs.globalCheckbox.checked = selected
			}
			this.selectChange()
		},
		/**
		 * Handles global checkbox change
		 */
		globalSelectionChange() {
			for(let item of this.data) this.selectedState[item.id] = this.$refs.globalCheckbox.checked
			this.allowAllSelection = this.$refs.globalCheckbox.checked && this.pages.length > 1
			this.allSelected = null
			this.selectChange()
		},
		/**
		 * Reset selection
		 */
		resetSelection() {
			this.selectedState = {}
			this.handleSelectionState()
		},
		/**
		 * Set selected items
		 * @param ids
		 */
		setSelection(ids) {
			this.selectedState = {}
			for(let id of ids) this.selectedState[id] = true
			this.handleSelectionState()
		},
		selectAll() {
			this.allSelectionLoading = true
			let req = this.getRequestParams()
			req.data.fields = [{name: 'id'}]
			req.data.limit = -1
			req.data.offset = 0
			Api.post('api/', req).then(resp => {
				this.allSelected = resp.data.data.map(i => i.id)
				this.allSelectionLoading = false
				this.selectChange()
			})
		},
		selectChange() {
			const [selectedItems, collection] = this.getSelection()
			if(this._firstDataLoadDone) this.$emit('selectChange', [selectedItems, collection])
			if(this.routerSelectionState === false) return
			if(selectedItems.length) {
				let title = this.selectionTitle ? this.selectionTitle : this.model + '.title.selection'
				if(selectedItems.length > 1) title += '{count}'
				Router.selectModeEnable(this.$t(title, {count: selectedItems.length}), collection)
			}
			else Router.selectModeDisable()
		},
		getSelection() {
			let selectedItems = this.allSelected ? this.allSelected : Object.keys(this.selectedState).filter(k => this.selectedState[k])
			let collection = null
			selectedItems.sort()
			if(selectedItems.length) {
				if((selectedItems[0] + '').includes('|')) {
					collection = '__instance_mc'
					let lastModel = null
					for(let item of selectedItems) {
						const [model, id] = item.split('|')
						if(model != lastModel) {
							if(lastModel) collection = collection.slice(0, -1)
							collection += '|' + model + '|'
							lastModel = model
						}
						collection += id + ','
					}
					collection = collection.slice(0, -1)
				}
				else collection = '__instance_c|' + this.modelMeta.name + '|' + selectedItems.join(',')
			}
			return [selectedItems, collection]
		},
		openMobileFilters() {
			this.mobileFilters = true
			Dialog.custom({
				component: 'BlListMobileFilters',
				componentProps: {blList: this},
				class: 'blListMobileFiltersContainer',
				closeButton: false
			})
		},
		openPaginationSettings() {
			Dialog.custom({
				component: 'BlListPaginationSettings',
				componentProps: {blList: this}
			})
		},
		initializeMobileSearch() {
			if(this.disableMobileSearch !== undefined && this.disableMobileSearch !== false) return
			const fieldFilters = this.getFields()
			let availableFields = []
			for(let fieldName of this.fieldNames) {
				let fieldOptions = fieldFilters.filter(f => f[fieldName])
				fieldOptions = fieldOptions.length ? fieldOptions[0][fieldName] : null
				if(fieldOptions?.filter && this.metadata[fieldName].type == 'string') availableFields.push(fieldName)
			}
			if(availableFields.length) {
				this.mobileFilterSearch.fields = availableFields
				this.mobileFilterSearch.enabled = true
			}
		},
		getMobileSearchFilters() {
			if(!this.mobileFilterSearch.enabled || !this.mobileFilterSearch.search.length) return null
			return ['|'].concat(this.mobileFilterSearch.fields.map(f => [f, '*=*', this.mobileFilterSearch.search]))
		},
		/**
		 * Called when BlListField is added / removed from slot
		 */
		handleFieldUpdate() {
			if(!this._initialLoad) return
			//Debounce
			if(this._fieldUploadDebouncer) clearTimeout(this._fieldUploadDebouncer)
			this._fieldUploadDebouncer = setTimeout(() => {
				this._fieldsUpdateing = true
				this.loadData(false, true).once(() => this._fieldsUpdateing = false)
			})
		}
	}
}
</script>

<style scoped lang="scss">
	.tableFooter {
		display: flex;
		align-items: center;
		padding: 0 10px;
		background-color: var(--bl-background);
		border-radius: var(--bl-border-radius);
		border-top-right-radius: 0;
		border-top-left-radius: 0;

		button {
			cursor: pointer;
		}
	}

	.tableFooter > div {
		color: var(--bl-legend);
		align-items: center;
		display: flex;
		white-space: nowrap;
		font-size: 12px;

		select {
			margin-left: 5px;
		}
	}

	.mobileFilterIcon {
		margin-right: 3px;
		color: var(--bl-primary);
		opacity: 0.8;
	}

	.filterBackdrop {
		background-color: var(--bl-primary);
		width: 100%;
		position: absolute;
		height: 100%;
		margin-top: -16px;
		margin-left: -7px;
		opacity: 0.2;
		animation: filter-backdrop-fadein .2s;
	}

	table.mobile .filterBackdrop {
		margin-top: -16px;
	}

	table.mobile.selectable .filterBackdrop {
		margin-top: -21px;
	}

	thead tr th:first-child .filterBackdrop {
		border-top-left-radius: var(--bl-border-radius);
	}

	thead tr th:last-child .filterBackdrop {
		border-top-right-radius: var(--bl-border-radius);
	}

	thead tr:nth-child(2) .filterBackdrop {
		margin-top: 0;
		border-radius: 0 !important;
	}

	thead tr th span {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	thead tr th.isSticky {
		padding-top: 16px;
		border-radius: 0 !important;
		box-shadow: inset 0px -1px 0px 0px var(--bl-border);

		.filterBackdrop {
			border-radius: 0 !important;
		}
	}

	@keyframes filter-backdrop-fadein {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 0.2;
		}
	}

	.droppedDownRow :deep .noDataCell {
		svg {
			display: none;
		}

		div {
			margin-bottom: 0;
			font-family: Roboto;
			font-size: 14px;
			color: var(--bl-legend);
		}
	}

	.droppedDownRow :deep {
		table.bl-datatable > thead > tr > th {
			background-color: var(--bl-surface);
		}
	}

	table thead tr th {
		position: relative;
	}

	table thead tr:nth-child(2) th {
		font-weight: normal;
	}

	tr.noDataCell:hover {
		background-color: transparent;
	}

	table.mobile thead .labelHeader .bl-checkbox {
		top: -17px;
	}

	table thead tr.labelHeader th {
		position: sticky;
		top: -1px;
		z-index: 3;

		> .bl-checkbox:indeterminate {
			margin-top: 2px;
		}

		div > icon {
			font-size: 16px;
			margin-left: 5px;
			vertical-align: text-top;
			transition: transform 0.2s;

		}

		div > icon.reversed {
			transform: rotate(180deg);
		}
	}

	tr.selected, tr.selected:hover {
		background-color: var(--bl-selected-bg);
	}

	.bl-datatable.selectMode thead tr:nth-child(2) th:first-child {
		border-right: 1px solid var(--bl-border);
	}

	.bl-datatable.selectMode tr > :is(td,th):first-child {
		border-right: 0;
		border-left: 0;
	}

	.bl-datatable.selectMode tr > :is(td,th):nth-child(2) {
		border-left: 0;
	}

	.dropdownMode tr:not(.droppedDownRow) td {
		cursor: pointer;
	}

	.dropdownMode tr.droppedDownRow {
		background-color: var(--bl-background);
	}

	.dropdownMode tr.droppedDownRow > td {
		padding: 0;
	}

	.dropdownMode tr.droppedDownRow > td > .bl-datatable {
		margin: 0;
		border-radius: 0;

		td, th {
			border-radius: 0;
		}
	}

	.bl-icon-button.dropdownButton {
		transition: transform .2s;
	}

	.bl-icon-button.dropdownButton.opened {
		transform: rotate(90deg);
	}

	td.selectableContainer {
		padding: 11px 7px 0 7px;
		vertical-align: top;
	}

	table.mobile td.selectableContainer {
		padding-top: 15px;
	}

	td.field-error {
		background: repeating-linear-gradient(45deg, var(--bl-error), var(--bl-error) 10px, var(--bl-surface) 10px, var(--bl-surface) 20px);
		opacity: .2;
	}

	tr.aggregations {
		td, td:hover {
			cursor: default;
			text-align: right;
			font-weight: 500;
			background-color: var(--bl-surface);
			border-top: 2px solid var(--bl-border);
		}
	}

	.mobileVirtualScroller {
		background-color: var(--bl-surface);
		position: absolute;
		width: calc(100% - 10px);
		z-index: 0;
		margin: 55px 5px 5px 5px;
		outline: 1px solid var(--bl-border);
		border-bottom-left-radius: var(--bl-border-radius);
		border-bottom-right-radius: var(--bl-border-radius);
		background-size: 100% 55px;
	}

	.mobileVirtualScroller:after {
		content: '_';
		display: block;
		position: absolute;
		top: 100%;
		opacity: 0;
		overflow: hidden;
		height: 5px;
		min-height: 5px;
	}

	table.bl-datatable.mobile {
		table-layout: fixed;
		background-color: transparent;
		position: absolute;

		tbody tr:last-child td:first-child {
			border-bottom-left-radius: var(--bl-border-radius);
		}

		tbody tr:last-child td:last-child {
			border-bottom-right-radius: var(--bl-border-radius);
		}

		tr, td {
			height: 26px;
			max-height: 26px;
		}

		tr > td {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		tr:not(.mobileOffsetCell):not(.selected) td {
			background-color: var(--bl-surface);
		}

		tr.mobileOffsetCell td {
			padding: 0;
			border-top: 0;
			border-bottom: 0;
		}

		tr.mobileOffsetCell:hover {
			background-color: transparent;
		}
	}

	div.linesPerPage:hover > button.bl-icon-button.linesPerPageSettings {
		display: block;
	}

	button.bl-icon-button.linesPerPageSettings {
		color: var(--bl-legend);
		font-size: 14px;
		display: none;
		margin: 0 4px;
	}

	.mobileSearchWithTextContainer {
		display: flex;
		align-items: center;

		> icon {
			display: none;
			color: var(--bl-legend);
		}

		> input {
			height: 35px;
			border-radius: var(--bl-border-radius);
			outline: 1px solid var(--bl-border);
			margin-left: 5px;
			padding: 0 10px;
			border: 0;
			flex: 1;
		}

		> input:focus {
			outline: 2px solid var(--bl-primary);
		}

		> button.bl-icon-button {
			color: var(--bl-legend);
			margin-left: -44px;
		}
	}
</style>