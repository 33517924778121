import { Auth } from 'AuthBundle'
import { RefreshToken } from 'Bundles/AuthBundle/services/RefreshToken.js'
import { ViewServices, Snackbar, Router, Variables } from 'InterfaceBundle'

export var Api = {
	urids: {},//Unique request ids

	/**
	 * Api get
	 */
	get(url, params = {}, headers = {}, urid) {
		return this.request('GET', url, params, headers, true, false, urid)
	},

	/**
	 * Api post
	 */
	post(url, params = {}, headers = {}, urid) {
		return this.request('POST', url, params, headers, true, false, urid)
	},

	/**
	 * Api put
	 */
	put(url, params = {}, headers = {}, urid) {
		return this.request('PUT', url, params, headers, true, false, urid)
	},

	/**
	 * Api delete
	 */
	delete(url, params = {}, headers = {}, urid) {
		return this.request('DELETE', url, params, headers, true, false, urid)
	},

	/**
	 * Api post
	 */
	request(method, url, params = {}, headers = {}, authenticate = true, rawResponse = false, urid = null) {
		let urlRaw = url
		if(url.substr(0, 1) == '/') url = url.substring(1)

		let options = {method: method, headers: {
			'Content-Type': 'application/json'
		}}
		if(authenticate && Auth.getToken()) options.headers.Authorization = 'Bearer ' + Auth.getToken()
		if(authenticate && Auth.isImpersonating()) options.headers.Impersonate = Auth.isImpersonating()
		if(Router.currentRoute?.definition?.context) options.headers['Router-Context'] = Router.currentRoute.definition.context
		if(Auth.getDeviceType()) options.headers.BlDeviceType = Auth.getDeviceType();

		if(params && Object.entries(params).length) {
			this._formatParameters(params)
			if(['POST', 'PUT'].includes(method)) {
				options.body = JSON.stringify(params)
			}
			else {
				url = this._parseGetUrl(url, params)
			}
		}

		if(headers && Object.entries(headers).length) {
			for(let key in headers) options.headers[key] = headers[key]
		}

		if(urid) {
			if(this.urids[urid]) this.urids[urid].abort()
			this.urids[urid] = new AbortController()
			options.signal = this.urids[urid].signal
		}

		return new Promise((resolve, reject) => {
			let catchRequest = true
			fetch('/api/' + url, options).then(resp => {
				if(!resp.ok) {
					if(authenticate) {
						catchRequest = false
						resp.text().then(text => {
							if(text == '{"__error":"error"}') Snackbar.open({text: 'An error occurred', error: true})
							else if(text == 'token.invalid' && urlRaw != '/auth/refresh_token') {
								RefreshToken.refresh().then(() => {
									this.request(method, urlRaw, params, headers, authenticate).then(resp => resolve(resp)).catch(err => reject(err))
								}).catch(err => reject(err))
							}
							else if(text == 'permission.notallowed') Router.notFound()
							else if(text == 'updating' && resp.status == 503) Variables.setUpgrading(true)
							else {
								try {
									reject(JSON.parse(text))
								} catch(e) {
									reject(text)
								}
							}
						})
						if(resp.status == 401) return 'token.invalid'
					}
					else {
						reject(resp)
						throw Error('Error')
					}
				}
				else return resp
			})
			.then(resp => {
				if(resp) Variables.setUpgrading(false)
				if(resp !== 'token.invalid') {
					if(resp) return rawResponse ? resp : resp.json()
					else if(catchRequest) throw Error('Error')
				}
			})
			.then(resp => {
				if(resp) resolve(resp)
			})
			.catch(e => reject(e))// <= removed because error handling on form does not work otherwise
		})
	},

	/**
	 * Open new window if API route
	 * @param  {string} url
	 * @param  {Object} params
	 */
	openWindow(url, params = {}) {
		window.open(this.getUrl(url, params))
	},

	/**
	 * Generate URL and append tocken
	 * @param  {string} url
	 * @param  {Object} params
	 * @return {string}
	 */
	getUrl(url, params = {}) {
		if(url.substr(0, 1) == '/') url = url.substring(1)
		params.bearer = Auth.getToken()
		return this._parseGetUrl('/api/' + url, params)
	},

	/**
	 * Parse url for get request
	 * @param  {string} url
	 * @param  {Object} params
	 * @return {string}
	 */
	_parseGetUrl(url, params) {
		let encodedParams = ''
		for(let key in params) {
			if(encodedParams != '') encodedParams += '&'
			encodedParams += key + '=' + encodeURIComponent(params[key])
		}
		return url + '?' + encodedParams
	},

	/**
	 * Recursive parameter formatting (date)
	 * @param  {Object} parameters
	 * @return {Object}
	 */
	_formatParameters(params) {
		if(params instanceof Date) return this._formatDate(params)
		else if(Array.isArray(params) || typeof params === 'object') {
			for(let k in params) params[k] = this._formatParameters(params[k])
			return params
		}
		else return params
	},

	/**
	 * Format date for API (handles TZ)
	 * @param  {Date} date
	 * @return {string}
	 */
	_formatDate(date = null) {
		if(!date) date = new Date()
		let tzoffset = (date).getTimezoneOffset() * 60000
		date = (new Date(date.getTime() - tzoffset))
		return date.toISOString().substr(0, 19).replace('T', ' ')
	}
}

ViewServices.api = Api
ViewServices.auth = Auth
