<template>
	<div style="position: relative;">
		<div v-bl-input>
			<input readonly type="text" v-model="displayValue" @focus="toggleCalendar()" ref="inputElement" style="anchor-name: --bl-calendar-container-anchor;" />
			<span class="suffix material-icons" @click="clear()" style="visibility: visible; margin-top: 10px;">{{ hasValue() ? 'remove_circle_outline' : '' }}</span>
		</div>
		<div class="bl-calendar-container">
			<BlCalendarInput :value="valueFrom" @change="this.setValueFrom($event); setFilter()" :format="'yyyy-MM-dd HH:mm'" :opened="showCalendar" @openedChange="preventClose ? null : openedChange($event)" :disableBodyAppend="true" @heightChange="calendarFromHeight = $event">
				<div class="timeSelector bl-card" v-if="showCalendar" :style="{marginTop: calendarFromHeight - 23 + 'px'}">
					<BlSelect :data="hours" label="Hours" v-model="valueFromHours" @change="valueFrom = setTime(valueFrom, valueFromHours, 'HH'); setFilter()" @blur="preventCloseCalendar()" :disabled="!valueFrom" />
					<div style="min-width: 5px;"></div>
					<BlSelect :data="minutes" label="Minutes" v-model="valueFromMinutes" @change="valueFrom = setTime(valueFrom, valueFromMinutes, 'mm'); setFilter()" @blur="preventCloseCalendar()" :disabled="!valueFrom" />
				</div>
				<div class="secondaryCalendar" v-if="showCalendar">
					<BlCalendarInput :value="valueTo" @change="this.setValueTo($event); setFilter()" :format="'yyyy-MM-dd HH:mm'" :opened="true" :disableBodyAppend="true" :disableAnimation="true">
						<div class="timeSelector bl-card">
							<BlSelect :data="hours" label="Hours" v-model="valueToHours" @change="valueTo = setTime(valueTo, valueToHours, 'HH'); setFilter()" @blur="preventCloseCalendar()" :disabled="!valueTo" />
							<div style="min-width: 5px;"></div>
							<BlSelect :data="minutes" label="Minutes" v-model="valueToMinutes" @change="valueTo = setTime(valueTo, valueToMinutes, 'mm'); setFilter()" @blur="preventCloseCalendar()" :disabled="!valueTo" />
						</div>
					</BlCalendarInput>
				</div>
			</BlCalendarInput>
		</div>
	</div>
</template>

<script>
import { DateFormat } from 'InterfaceBundle'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)

export default {
	name: 'BlListFilterRangeDatetime',
	props: ['initialValue', 'metadata'],
	emits: ['blListFilterChange'],
	data() {
		return {
			displayValue: '',
			valueFrom: null,
			calendarFromHeight: null,
			valueFromHours: '00',
			valueFromMinutes: '00',
			valueTo: null,
			valueToHours: '23',
			valueToMinutes: '59',
			showCalendar: false,
			hours: new Map(),
			minutes: new Map(),
			preventClose: false
		}
	},
	created() {
		if(this.initialValue) {
			this.valueFrom = this.initialValue[0]
			this.valueFromHours = this.valueFrom.substr(11, 2)
			this.valueFromMinutes = this.valueFrom.substr(14, 2)
			this.valueTo = this.initialValue[1]
			this.valueToHours = this.valueTo.substr(11, 2)
			this.valueToMinutes = this.valueTo.substr(14, 2)
		}
		this.setDisplayValue()
		for(let i = 0; i < 24; i++) {
			let v = (i < 10 ? '0' : '') + i
			this.hours.set(v, v)
		}
		for(let i = 0; i < 60; i++) {
			let v = (i < 10 ? '0' : '') + i
			this.minutes.set(v, v)
		}
	},
	methods: {
		toggleCalendar() {
			this.showCalendar = !this.showCalendar
		},
		setFilter() {
			if(this.hasValue()) this.$emit('blListFilterChange', [['&', [this.metadata.field.name, '>=', this.valueFrom], [this.metadata.field.name, '<=', this.valueTo]], [this.valueFrom, this.valueTo]])
			else this.$emit('blListFilterChange', null)
			this.setDisplayValue()
		},
		setDisplayValue() {
			this.displayValue = this.hasValue() ? (this.valueFrom + ', ' + this.valueTo) : ''
		},
		hasValue() {
			return this.valueFrom && this.valueTo
		},
		clear() {
			this.valueFrom = null
			this.valueFromHours = '00'
			this.valueFromMinutes = '00'
			this.valueTo = null
			this.valueToHours = '00'
			this.valueToMinutes = '00'
			this.setFilter()
		},
		setValueFrom(value) {
			this.valueFrom = value
			this.valueFrom = this.setTime(this.valueFrom, this.valueFromHours, 'HH')
			this.valueFrom = this.setTime(this.valueFrom, this.valueFromMinutes, 'mm')
		},
		setValueTo(value) {
			this.valueTo = value
			this.valueTo = this.setTime(this.valueTo, this.valueToHours, 'HH')
			this.valueTo = this.setTime(this.valueTo, this.valueToMinutes, 'mm')
		},
		setTime(model, value, type) {
			let valueMask = DateFormat.createMask('yyyy-MM-dd HH:mm'.replace(type, '~ '))
			let index = valueMask.indexOf('~')
			model = model.substr(0, index) + value + model.substr(index + 2)
			return model
		},
		preventCloseCalendar() {
			this.preventClose = true
			setTimeout(() => this.preventClose = false)
		},
		openedChange(value) {
			this.showCalendar = value
			if(document.activeElement === this.$refs.inputElement) this.showCalendar = true
		}
	}
}
</script>

<style scoped lang="scss">
	.timeSelector {
		margin-left: -1px;
		z-index: 10;
		display: flex;
		width: 270px;
	}

	.suffix {
		cursor: pointer;
	}

	div.bl-input .suffix {
		margin-right: -35px;
	}

	div.bl-input.__suffix {
		width: calc(100% - 51px);
	}

	div.bl-input input {
		padding-right: 35px;
	}

	.bl-calendar-container {
		margin-left: 5px;
		margin-top: 2px;
		display: flex;
		position: fixed;
		z-index: 2;
		position-anchor: --bl-calendar-container-anchor;
		justify-self: anchor-center;

		:deep > div > .calendar {
			position: relative !important;
			margin-top: -35px;
		}

		:deep > div:nth-child(2) {
			position: relative !important;
			margin-top: 40px;

			> .timeSelector {
				position: absolute;
				left: -285px;
				z-index: 3;
			}
		}

		:deep > div:nth-child(2) > .secondaryCalendar > div:nth-child(2) {
			margin-top: 37px;
		}

		.secondaryCalendar {
			margin-top: -80px;
		}

		.secondaryCalendar :deep .calendar {
			position: relative !important;
		}
	}
</style>